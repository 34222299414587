import { AUTOMATION_RULES_FILTER_API } from 'modules/automation-rules/api/automation-rules-api'
import {
  BlogEntityEnum,
  BlogPageEnum,
} from 'modules/automation-rules/enums/automation-rule-enum'
import {
  AutomationRuleTriggersDataEnum,
  AutomationRuleTriggersEnum,
} from 'modules/automation-rules/triggers/enums/trigger-enum'
import { getShouldFetchTriggers } from 'modules/automation-rules/triggers/utils/get-should-fetch-triggers'
import {
  AutomationRuleFilterInterface,
  BlogEntityDataInterface,
  FilterBaseApiInterface,
  FilterBlogApiInterface,
  FilterEmailApiInterface,
  FilterEmailLinkApiInterface,
} from 'modules/automation-rules/types/automation-rule-filter-interface'
import { AutomationRuleBaseDataInterface } from 'modules/automation-rules/types/automation-rule-interface'
import {
  getBlogEntityData,
  getData,
} from 'modules/automation-rules/utils/get-filter-data'
import { Dispatch, SetStateAction, useState } from 'react'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import useSWR from 'swr'

export interface TriggersDataInterface {
  tagData?: AutomationRuleBaseDataInterface[]
  campaignData?: AutomationRuleBaseDataInterface[]

  blogData?: AutomationRuleBaseDataInterface[]
  blogEntityData?: BlogEntityDataInterface
  blogPagesData?: AutomationRuleBaseDataInterface[]
  blogPostsData?: AutomationRuleBaseDataInterface[]

  funnelsFormSubscribeData?: AutomationRuleBaseDataInterface[]
  funnelStepsFormSubscribeData?: AutomationRuleBaseDataInterface[]

  funnelsNewSaleData?: AutomationRuleBaseDataInterface[]
  funnelStepsNewSaleData?: AutomationRuleBaseDataInterface[]

  funnelsSaleCanceledData?: AutomationRuleBaseDataInterface[]
  funnelStepsSaleCanceledData?: AutomationRuleBaseDataInterface[]

  funnelsPageVisitedData?: AutomationRuleBaseDataInterface[]
  funnelStepsPageVisitedData?: AutomationRuleBaseDataInterface[]

  funnelsWebinarData?: AutomationRuleBaseDataInterface[]
  webinarData?: AutomationRuleBaseDataInterface[]

  courseData?: AutomationRuleBaseDataInterface[]
  moduleData?: AutomationRuleBaseDataInterface[]
  lectureData?: AutomationRuleBaseDataInterface[]
  courseBundleData?: AutomationRuleBaseDataInterface[]

  communityData?: AutomationRuleBaseDataInterface[]

  emailData?: AutomationRuleBaseDataInterface[]

  emailLinksData?: AutomationRuleBaseDataInterface[]

  funnelsSubscriptionPaymentFailedData?: AutomationRuleBaseDataInterface[]
  funnelStepsSubscriptionPaymentFailedData?: AutomationRuleBaseDataInterface[]
}

export function useTriggersData(triggerItem: AutomationRuleFilterInterface): {
  triggersData: TriggersDataInterface
  setShouldFetch: Dispatch<SetStateAction<Record<AutomationRuleTriggersDataEnum, boolean>>>
} {
  const { user } = useUser()
  const [shouldFetch, setShouldFetch] = useState(getShouldFetchTriggers())
  const {
    trigger,
    formSubscribeFunnelId,
    pageVisitedFunnelId,
    newSaleFunnelId,
    cancelSaleFunnelId,
    blogId,
    blogEntity,
    webinarFunnelId,
    triggerCourseId,
    triggerModuleId,
    subscriptionFailedFunnelId,
    triggerMailingId,
  } = triggerItem

  const { fetcher: baseFetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    FilterBaseApiInterface[]
  >({ method: RequestMethodsEnum.get })

  const { fetcher: blogFetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    FilterBlogApiInterface[]
  >({ method: RequestMethodsEnum.get })

  const { fetcher: emailFetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    FilterEmailApiInterface[]
  >({ method: RequestMethodsEnum.get })

  const { fetcher: emailLinkFetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    FilterEmailLinkApiInterface[]
  >({ method: RequestMethodsEnum.get })

  const { data: tags } = useSWR(() => {
    if (user && shouldFetch.tag) {
      return `${AUTOMATION_RULES_FILTER_API}/trigger-type/tag`
    }
    return null
  }, baseFetcher)

  const { data: campaigns } = useSWR(() => {
    if (user && shouldFetch.campaign_completed) {
      return `${AUTOMATION_RULES_FILTER_API}/trigger-type/campaign`
    }
    return null
  }, baseFetcher)

  const { data: blogs } = useSWR(() => {
    if (user && shouldFetch.blog_page_form_subscribed) {
      return `${AUTOMATION_RULES_FILTER_API}/trigger-type/blog`
    }
    return null
  }, blogFetcher)

  const { data: blogPages } = useSWR(() => {
    if (
      user &&
      shouldFetch.blog_page_form_subscribed_page &&
      blogId &&
      (blogEntity === BlogEntityEnum.blog_page ||
        blogEntity === BlogPageEnum.blog_static ||
        blogEntity === BlogPageEnum.blog_home)
    ) {
      return `${AUTOMATION_RULES_FILTER_API}/trigger-type/blog-pages/${blogId}/page`
    }
    return null
  }, baseFetcher)

  const { data: blogPosts } = useSWR(() => {
    if (
      user &&
      shouldFetch.blog_page_form_subscribed_post &&
      blogId &&
      blogEntity === BlogEntityEnum.blog_post_body
    ) {
      return `${AUTOMATION_RULES_FILTER_API}/trigger-type/blog-pages/${blogId}/post`
    }
    return null
  }, baseFetcher)

  const { data: funnelsFormSubscribe } = useSWR(() => {
    if (
      user &&
      shouldFetch.form_subscribed &&
      trigger === AutomationRuleTriggersEnum.form_subscribed
    ) {
      return `${AUTOMATION_RULES_FILTER_API}/trigger-type/funnel/${trigger}`
    }
    return null
  }, baseFetcher)

  const { data: funnelStepsFormSubscribe } = useSWR(() => {
    if (
      user &&
      shouldFetch.form_subscribed_funnel_step &&
      formSubscribeFunnelId &&
      trigger === AutomationRuleTriggersEnum.form_subscribed
    ) {
      return `${AUTOMATION_RULES_FILTER_API}/trigger-type/funnel-step/${trigger}/${formSubscribeFunnelId}`
    }
    return null
  }, baseFetcher)

  const { data: funnelsNewSale } = useSWR(() => {
    if (user && shouldFetch.new_sale && trigger === AutomationRuleTriggersEnum.new_sale) {
      return `${AUTOMATION_RULES_FILTER_API}/trigger-type/funnel/${trigger}`
    }
    return null
  }, baseFetcher)

  const { data: funnelStepsNewSale } = useSWR(() => {
    if (
      user &&
      shouldFetch.new_sale_funnel_step &&
      newSaleFunnelId &&
      trigger === AutomationRuleTriggersEnum.new_sale
    ) {
      return `${AUTOMATION_RULES_FILTER_API}/trigger-type/funnel-step/${trigger}/${newSaleFunnelId}`
    }
    return null
  }, baseFetcher)

  const { data: funnelsSaleCanceled } = useSWR(() => {
    if (user && shouldFetch.sale_canceled && trigger === AutomationRuleTriggersEnum.sale_canceled) {
      return `${AUTOMATION_RULES_FILTER_API}/trigger-type/funnel/${trigger}`
    }
    return null
  }, baseFetcher)

  const { data: funnelStepsSaleCanceled } = useSWR(() => {
    if (
      user &&
      shouldFetch.sale_canceled_funnel_step &&
      cancelSaleFunnelId &&
      trigger === AutomationRuleTriggersEnum.sale_canceled
    ) {
      return `${AUTOMATION_RULES_FILTER_API}/trigger-type/funnel-step/${trigger}/${cancelSaleFunnelId}`
    }
    return null
  }, baseFetcher)

  const { data: funnelsPageVisited } = useSWR(() => {
    if (user && shouldFetch.page_visited && trigger === AutomationRuleTriggersEnum.page_visited) {
      return `${AUTOMATION_RULES_FILTER_API}/trigger-type/funnel/${trigger}`
    }
    return null
  }, baseFetcher)

  const { data: funnelStepsPageVisited } = useSWR(() => {
    if (
      user &&
      shouldFetch.page_visited_funnel_step &&
      pageVisitedFunnelId &&
      trigger === AutomationRuleTriggersEnum.page_visited
    ) {
      return `${AUTOMATION_RULES_FILTER_API}/trigger-type/funnel-step/${trigger}/${pageVisitedFunnelId}`
    }
    return null
  }, baseFetcher)

  const { data: funnelsSubscriptionPaymentFailed } = useSWR(() => {
    if (
      user &&
      shouldFetch.subscription_payment_failed &&
      trigger === AutomationRuleTriggersEnum.subscription_payment_failed
    ) {
      return `${AUTOMATION_RULES_FILTER_API}/trigger-type/funnel/${trigger}`
    }
    return null
  }, baseFetcher)

  const { data: funnelStepsSubscriptionPaymentFailed } = useSWR(() => {
    if (
      user &&
      shouldFetch.subscription_payment_failed &&
      trigger === AutomationRuleTriggersEnum.subscription_payment_failed &&
      subscriptionFailedFunnelId
    ) {
      return `${AUTOMATION_RULES_FILTER_API}/trigger-type/funnel-step/${trigger}/${subscriptionFailedFunnelId}`
    }
    return null
  }, baseFetcher)

  const { data: funnelsWebinar } = useSWR(() => {
    if (
      user &&
      shouldFetch.registered_to_webinar &&
      trigger === AutomationRuleTriggersEnum.registered_to_webinar
    ) {
      return `${AUTOMATION_RULES_FILTER_API}/trigger-type/funnel/${trigger}`
    }
    return null
  }, baseFetcher)

  const { data: webinars } = useSWR(() => {
    if (user && shouldFetch.registered_to_webinar_funnel_step && webinarFunnelId) {
      return `${AUTOMATION_RULES_FILTER_API}/trigger-type/webinar/${webinarFunnelId}`
    }
    return null
  }, baseFetcher)

  const { data: courses } = useSWR(() => {
    if (user && shouldFetch.course) {
      return `${AUTOMATION_RULES_FILTER_API}/trigger-type/course`
    }
    return null
  }, baseFetcher)

  const { data: modules } = useSWR(() => {
    if (user && shouldFetch.module && triggerCourseId) {
      return `${AUTOMATION_RULES_FILTER_API}/trigger-type/membership/${triggerCourseId}/modules`
    }
    return null
  }, baseFetcher)

  const { data: lectures } = useSWR(() => {
    if (user && shouldFetch.lecture && triggerModuleId) {
      return `${AUTOMATION_RULES_FILTER_API}/trigger-type/membership/${triggerModuleId}/lectures`
    }
    return null
  }, baseFetcher)

  const { data: courseBundles } = useSWR(() => {
    if (user && shouldFetch.enrolled_in_course_bundle) {
      return `${AUTOMATION_RULES_FILTER_API}/trigger-type/course-bundle`
    }
    return null
  }, baseFetcher)

  const { data: communities } = useSWR(() => {
    if (user && shouldFetch.enrolled_in_community) {
      return `${AUTOMATION_RULES_FILTER_API}/trigger-type/community`
    }
    return null
  }, baseFetcher)

  const { data: emails } = useSWR(() => {
    if (user && shouldFetch.email) {
      return `${AUTOMATION_RULES_FILTER_API}/trigger-type/email-link-clicked`
    }
    return null
  }, emailFetcher)

  const { data: emailLinks } = useSWR(() => {
    if (user && shouldFetch.email_link && triggerMailingId) {
      return `${AUTOMATION_RULES_FILTER_API}/trigger-type/mailing/${triggerMailingId}/link-clicked`
    }
    return null
  }, emailLinkFetcher)

  return {
    triggersData: {
      tagData: getData(tags),

      campaignData: getData(campaigns),

      blogData: getData(blogs),
      blogEntityData: getBlogEntityData(blogs),
      blogPagesData: getData(blogPages),
      blogPostsData: getData(blogPosts),

      funnelsFormSubscribeData: getData(funnelsFormSubscribe),
      funnelStepsFormSubscribeData: getData(funnelStepsFormSubscribe),

      funnelsNewSaleData: getData(funnelsNewSale),
      funnelStepsNewSaleData: getData(funnelStepsNewSale),

      funnelsSaleCanceledData: getData(funnelsSaleCanceled),
      funnelStepsSaleCanceledData: getData(funnelStepsSaleCanceled),

      funnelsPageVisitedData: getData(funnelsPageVisited),
      funnelStepsPageVisitedData: getData(funnelStepsPageVisited),

      funnelsWebinarData: getData(funnelsWebinar),
      webinarData: getData(webinars),

      courseData: getData(courses),
      moduleData: getData(modules),
      lectureData: getData(lectures),
      courseBundleData: getData(courseBundles),

      communityData: getData(communities),

      emailData: emails?.map(el => ({ id: el.id, caption: el.subject })),

      emailLinksData: emailLinks?.map(el => ({ id: el.id, caption: el.originalUrl })),

      funnelsSubscriptionPaymentFailedData: getData(funnelsSubscriptionPaymentFailed),
      funnelStepsSubscriptionPaymentFailedData: getData(funnelStepsSubscriptionPaymentFailed),
    },
    setShouldFetch,
  }
}
