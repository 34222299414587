export enum AutomationRuleActionsEnum {
  subscribe_to_campaign = 'subscribe_to_campaign',
  unsubscribe_from_campaign = 'unsubscribe_from_campaign',
  add_tag = 'add_tag',
  remove_tag = 'remove_tag',
  send_email = 'send_email',
  send_email_to_specific_address = 'send_email_to_specific_address',
  enroll_in_course = 'enroll_in_course',
  revoke_access_to_course = 'revoke_access_to_course',
  create_user = 'create_user',
  send_webhook = 'send_webhook',
  grant_access_to_community = 'grant_access_to_community',
  revoke_access_to_community = 'revoke_access_to_community',
  enroll_in_course_bundle = 'enroll_in_course_bundle',
  revoke_access_to_course_bundle = 'revoke_access_to_course_bundle',
}
