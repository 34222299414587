import { AutomationRuleActionsEnum } from 'modules/automation-rules/actions/enums/action-enum'

export const actionTitleLocoKeys: Record<AutomationRuleActionsEnum, string> = {
  [AutomationRuleActionsEnum.add_tag]: 'dashboard.automation_rule.action.add_tag',
  [AutomationRuleActionsEnum.remove_tag]: 'dashboard.automation_rule.action.remove_tag',
  [AutomationRuleActionsEnum.send_email]: 'dashboard.automation_rule.action.send_email',
  [AutomationRuleActionsEnum.send_email_to_specific_address]:
    'dashboard.automation_rule.action.send_email_to_specific_address',
  [AutomationRuleActionsEnum.subscribe_to_campaign]:
    'dashboard.automation_rule.action.add_campaign',
  [AutomationRuleActionsEnum.unsubscribe_from_campaign]:
    'dashboard.automation_rule.action.remove_campaign_from_contact',
  [AutomationRuleActionsEnum.enroll_in_course]: 'dashboard.automation_rule.action.enroll_in_course',
  [AutomationRuleActionsEnum.revoke_access_to_course]:
    'dashboard.automation_rule.action.revoke_access_to_course',
  [AutomationRuleActionsEnum.send_webhook]: 'dashboard.automation_rule.action.send_webhook',
  [AutomationRuleActionsEnum.grant_access_to_community]:
    'dashboard.automation_rule.action.grant_access_to_community',
  [AutomationRuleActionsEnum.revoke_access_to_community]:
    'dashboard.automation_rule.action.revoke_access_to_community',
  [AutomationRuleActionsEnum.create_user]: 'dashboard.automation_rule.action.create_user',
  [AutomationRuleActionsEnum.enroll_in_course_bundle]:
    'dashboard.automation_rule.action.enroll_in_course_bundle',
  [AutomationRuleActionsEnum.revoke_access_to_course_bundle]:
    'dashboard.automation_rule.action.revoke_access_to_course_bundle',
}

export const actionDescriptionLocoKeys: Record<AutomationRuleActionsEnum, string> = {
  [AutomationRuleActionsEnum.add_tag]: 'dashboard.automation_rule.action.description.add_tag',
  [AutomationRuleActionsEnum.remove_tag]: 'dashboard.automation_rule.action.description.remove_tag',
  [AutomationRuleActionsEnum.send_email]: 'dashboard.automation_rule.action.description.send_email',
  [AutomationRuleActionsEnum.send_email_to_specific_address]:
    'dashboard.automation_rule.action.description.send_email_to_specific_address',
  [AutomationRuleActionsEnum.subscribe_to_campaign]:
    'dashboard.automation_rule.action.description.add_campaign',
  [AutomationRuleActionsEnum.unsubscribe_from_campaign]:
    'dashboard.automation_rule.action.description.remove_campaign_from_contact',
  [AutomationRuleActionsEnum.enroll_in_course]:
    'dashboard.automation_rule.action.description.enroll_in_course',
  [AutomationRuleActionsEnum.revoke_access_to_course]:
    'dashboard.automation_rule.action.description.revoke_access_to_course',
  [AutomationRuleActionsEnum.send_webhook]:
    'dashboard.automation_rule.action.description.send_webhook',
  [AutomationRuleActionsEnum.grant_access_to_community]:
    'dashboard.automation_rule.action.description.grant_access_to_community',
  [AutomationRuleActionsEnum.revoke_access_to_community]:
    'dashboard.automation_rule.action.description.revoke_access_to_community',
  [AutomationRuleActionsEnum.create_user]:
    'dashboard.automation_rule.action.description.create_user',
  [AutomationRuleActionsEnum.enroll_in_course_bundle]:
    'dashboard.automation_rule.description.enroll_in_course_bundle',
  [AutomationRuleActionsEnum.revoke_access_to_course_bundle]:
    'dashboard.automation_rule.description.revoke_access_to_course_bundle',
}
