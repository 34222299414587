import { AutomationRuleActionsEnum } from 'modules/automation-rules/actions/enums/action-enum'
import { getShouldFetchActions } from 'modules/automation-rules/actions/utils/get-should-fetch-actions'
import { AUTOMATION_RULES_FILTER_API } from 'modules/automation-rules/api/automation-rules-api'
import {
  FilterBaseApiInterface,
  FilterEmailApiInterface,
} from 'modules/automation-rules/types/automation-rule-filter-interface'
import { AutomationRuleBaseDataInterface } from 'modules/automation-rules/types/automation-rule-interface'
import { getData } from 'modules/automation-rules/utils/get-filter-data'
import { Dispatch, SetStateAction, useState } from 'react'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import useSWR, { KeyedMutator } from 'swr'

export interface ActionsDataInterface {
  campaignData?: AutomationRuleBaseDataInterface[]
  tagData?: AutomationRuleBaseDataInterface[]
  communitiesData?: AutomationRuleBaseDataInterface[]
  emailData?: AutomationRuleBaseDataInterface[]
  specificEmailData?: AutomationRuleBaseDataInterface[]
  courseData?: AutomationRuleBaseDataInterface[]
  courseBundleData?: AutomationRuleBaseDataInterface[]
  mutateEmailData?: KeyedMutator<FilterEmailApiInterface[]>
  mutateSpecificEmailData?: KeyedMutator<FilterEmailApiInterface[]>
  mutateTagsData?: KeyedMutator<FilterBaseApiInterface[]>
}

export function useActionsData(): {
  data: ActionsDataInterface
  setShouldFetch: Dispatch<SetStateAction<Record<AutomationRuleActionsEnum, boolean>>>
} {
  const { user } = useUser()

  const [shouldFetch, setShouldFetch] = useState<Record<AutomationRuleActionsEnum, boolean>>(
    getShouldFetchActions(),
  )

  const { fetcher: baseFetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    FilterBaseApiInterface[]
  >({ method: RequestMethodsEnum.get })

  const { fetcher: emailFetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    FilterEmailApiInterface[]
  >({ method: RequestMethodsEnum.get })

  const { data: campaignsData } = useSWR(() => {
    if (user && (shouldFetch.subscribe_to_campaign || shouldFetch.unsubscribe_from_campaign)) {
      return `${AUTOMATION_RULES_FILTER_API}/action-type/campaign`
    }
    return null
  }, baseFetcher)

  const { data: tagsData, mutate: mutateTagsData } = useSWR(() => {
    if (user && (shouldFetch.remove_tag || shouldFetch?.add_tag)) {
      return `${AUTOMATION_RULES_FILTER_API}/action-type/tag`
    }
    return null
  }, baseFetcher)

  const { data: emailsData, mutate: mutateEmailData } = useSWR(() => {
    if (user && shouldFetch?.send_email) {
      return `${AUTOMATION_RULES_FILTER_API}/action-type/email`
    }
    return null
  }, emailFetcher)

  const { data: specificEmailsData, mutate: mutateSpecificEmailData } = useSWR(() => {
    if (user && shouldFetch?.send_email_to_specific_address) {
      return `${AUTOMATION_RULES_FILTER_API}/action-type/specific-email`
    }
    return null
  }, emailFetcher)

  const { data: coursesData } = useSWR(() => {
    if (user && (shouldFetch?.enroll_in_course || shouldFetch.revoke_access_to_course)) {
      return `${AUTOMATION_RULES_FILTER_API}/action-type/course`
    }
    return null
  }, baseFetcher)

  const { data: communitiesData } = useSWR(() => {
    if (
      user &&
      (shouldFetch?.grant_access_to_community || shouldFetch?.revoke_access_to_community)
    ) {
      return `${AUTOMATION_RULES_FILTER_API}/action-type/community`
    }
  }, baseFetcher)

  const { data: courseBundlesData } = useSWR(() => {
    if (
      user &&
      (shouldFetch?.revoke_access_to_course_bundle || shouldFetch?.enroll_in_course_bundle)
    ) {
      return `${AUTOMATION_RULES_FILTER_API}/action-type/courseBundles`
    }
  }, baseFetcher)

  return {
    data: {
      campaignData: getData(campaignsData),
      tagData: getData(tagsData),
      communitiesData: getData(communitiesData),
      emailData: emailsData?.map(el => ({ id: el.id, caption: el.subject })),
      specificEmailData: specificEmailsData?.map(el => ({ id: el.id, caption: el.subject })),
      courseData: getData(coursesData),
      courseBundleData: getData(courseBundlesData),
      mutateEmailData,
      mutateSpecificEmailData,
      mutateTagsData,
    },
    setShouldFetch,
  }
}
